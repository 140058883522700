<template>
  <div>
    <v-row v-show="!loading">
      <v-col cols="12">
        <v-card class="pa-3">
          <NoticeTable :notices="notices" />
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getMediaNoticeApi()"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NoticeTable from './components/NoticeTable.vue';

export default {
  components: {
    NoticeTable
  },
  computed: {
    ...mapGetters({
      userRoleList: 'userRoleList'
    })
  },
  data() {
    return {
      notices: [],
      pagination: {
        page: 1,
        perPage: 15,
        totalPage: 1
      },
      loading: true
    };
  },
  async mounted() {
    await this.getMediaNoticeApi();
  },
  methods: {
    async getMediaNoticeApi(page = this.pagination.page) {
      this.loading = true;
      try {
        const {
          data: {
            notices: { data: data, total: totalCount }
          }
        } = await this.$axios.get(`/c/u/notices`, {
          params: {
            page: page,
            perPage: this.pagination.perPage,
            limit: 10
          }
        });

        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;

        this.notices = data.map(notice => {
          return {
            key: notice.id,
            category: notice.category,
            startAt: notice.start_at.substr(0, 10),
            content: notice.content,
            title: notice.title,
            isUrgent: notice.is_urgent
          };
        });
      } catch (err) {
        console.error(err);
        return [];
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
